var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row w-100 anonymous-user-need-help-dialog"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"col-12",staticStyle:{"font-weight":"500","color":"rgba(0, 0, 0, 0.5)","font-size":"13px","cursor":"pointer"},on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v(" NEED HELP? CONTACT US ")])]),_c('div',{staticClass:"contact_dialogs"},[_c('el-dialog',{attrs:{"title":"Contact Help","top":"12vh","visible":_vm.dialogVisible,"destroy-on-close":true,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isSendingMessage),expression:"isSendingMessage"}]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-left",staticStyle:{"font-weight":"500","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(" Your Full Name "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpFullName.$error && !_vm.$v.userHelpFullName.required
              ),expression:"\n                $v.userHelpFullName.$error && !$v.userHelpFullName.required\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Required")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpFullName.$error && !_vm.$v.userHelpFullName.maxLength
              ),expression:"\n                $v.userHelpFullName.$error && !$v.userHelpFullName.maxLength\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Too Long")]),_c('div',{staticClass:"mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userHelpFullName.$model),expression:"$v.userHelpFullName.$model",modifiers:{"trim":true}}],staticClass:"legacy-input bg-white",class:{ 'input-error': _vm.$v.userHelpFullName.$error },staticStyle:{"width":"100%","height":"calc(2.4rem) !important"},attrs:{"type":"text","placeholder":"Full Name "},domProps:{"value":(_vm.$v.userHelpFullName.$model)},on:{"enter":_vm.sendMessage,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userHelpFullName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-md-12 text-left mt-2",staticStyle:{"font-weight":"500","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(" Your Email "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpEmailAddress.$error &&
                !_vm.$v.userHelpEmailAddress.required
              ),expression:"\n                $v.userHelpEmailAddress.$error &&\n                !$v.userHelpEmailAddress.required\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Required")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpEmailAddress.$error && !_vm.$v.userHelpEmailAddress.email
              ),expression:"\n                $v.userHelpEmailAddress.$error && !$v.userHelpEmailAddress.email\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Invalid Email")]),_c('div',{staticClass:"mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userHelpEmailAddress.$model),expression:"$v.userHelpEmailAddress.$model",modifiers:{"trim":true}}],staticClass:"legacy-input bg-white",class:{ 'input-error': _vm.$v.userHelpEmailAddress.$error },staticStyle:{"width":"100%","height":"calc(2.4rem) !important"},attrs:{"type":"text","placeholder":"Full Email "},domProps:{"value":(_vm.$v.userHelpEmailAddress.$model)},on:{"enter":_vm.sendMessage,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userHelpEmailAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-md-12 text-left mt-2",staticStyle:{"font-weight":"500","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(" Subject "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.userHelpSubject.$error && !_vm.$v.userHelpSubject.required),expression:"$v.userHelpSubject.$error && !$v.userHelpSubject.required"}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Required")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpSubject.$error && !_vm.$v.userHelpSubject.maxLength
              ),expression:"\n                $v.userHelpSubject.$error && !$v.userHelpSubject.maxLength\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Too Long")]),_c('div',{staticClass:"mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userHelpSubject.$model),expression:"$v.userHelpSubject.$model",modifiers:{"trim":true}}],staticClass:"legacy-input bg-white",class:{ 'input-error': _vm.$v.userHelpSubject.$error },staticStyle:{"width":"100%","height":"calc(2.4rem) !important"},attrs:{"type":"text","placeholder":"Subject"},domProps:{"value":(_vm.$v.userHelpSubject.$model)},on:{"enter":_vm.sendMessage,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.userHelpSubject, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-md-12 text-left mt-2",staticStyle:{"font-weight":"500","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(" Message "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.userHelpMessage.$error && !_vm.$v.userHelpMessage.required),expression:"$v.userHelpMessage.$error && !$v.userHelpMessage.required"}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Required")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$v.userHelpMessage.$error && !_vm.$v.userHelpMessage.maxLength
              ),expression:"\n                $v.userHelpMessage.$error && !$v.userHelpMessage.maxLength\n              "}],staticClass:"pl-2",staticStyle:{"color":"red","font-size":"0.8em"}},[_vm._v("Too Long")]),_c('div',{staticClass:"mt-1"},[_c('el-input',{class:{ 'input-error': _vm.$v.userHelpMessage.$error },staticStyle:{"width":"100% !important"},attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 3},"placeholder":"Type message here..."},on:{"enter":_vm.sendMessage},model:{value:(_vm.$v.userHelpMessage.$model),callback:function ($$v) {_vm.$set(_vm.$v.userHelpMessage, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.userHelpMessage.$model"}})],1)])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12 text-center",staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:('rgba(255, 255, 255, 0.35)'),expression:"'rgba(255, 255, 255, 0.35)'"}],staticClass:"btn z-depth-0",class:{ 'not-allowed': _vm.$v.$invalid },staticStyle:{"margin":"0px !important","text-transform":"capitalize","height":"45px","border-radius":"4px","width":"100%","background-color":"var(--el-app-primary)"},attrs:{"type":"button","disabled":_vm.$v.$invalid},on:{"click":_vm.sendMessage}},[_c('span',{staticStyle:{"position":"relative","bottom":"1px"}},[_vm._v("Send Message")])])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }