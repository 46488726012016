<template>
  <div class="row w-100 didnt-receive-code-dialog">
    <div class="didnt_receive_code">
      <el-dialog
        title="Choose Authorization Method"
        top="17vh"
        :visible.sync="isVisible"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
      >
        <div class="row w-100 px-3 mx-0" v-loading="isLoading">
          <div class="col-12 text-left">
            To Authorize Account Login, choose any of the following methods
          </div>
          <div class="col-12 text-left mt-3">
            <div class="form-group mb-0">
              <input
                name="answer"
                type="radio"
                class="with-gap"
                value="phone"
                :disabled="!formattedPhone"
                v-model="actionOption"
                id="resendCode"
              />
              <label
                :class="{ disabled: !formattedPhone }"
                for="resendCode"
                style="font-weight: 400; font-size: 1em"
                ><span v-if="!!formattedPhone" class="pr-2"
                  >Send Code to phone number {{ formattedPhone }}
                </span>
                <span v-else
                  >You cant use your Phone Number as it is invalid, Please
                  Contact Admin.</span
                >
              </label>
            </div>
          </div>
          <div class="col-12 text-left">
            <div class="form-group mb-0">
              <input
                name="answer"
                type="radio"
                class="with-gap"
                value="email"
                v-model="actionOption"
                id="changeOption"
              />
              <label for="changeOption" style="font-weight: 400; font-size: 1em"
                ><span class="pr-2"
                  >Send Code to email {{ formattedEmail }}
                </span>
              </label>
            </div>
          </div>
          <div class="col-12 mt-2 pr-0 text-right">
            <!-- {{actionOption}} -->
            <button
              @click="onContinue"
              :disabled="actionOption != 'email' && actionOption != 'phone'"
              type="button"
              class="btn z-depth-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 150px;
                text-transform: capitalize;
                height: 42px;
                border-radius: 4px;
                background-color: var(--el-app-primary);
              "
            >
              <span style="position: relative; bottom: 1px">Continue</span>
            </button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      isLoading: false,
      actionOption: "",
      isVisible: false,
    };
  },

  methods: {
    onContinue() {
      if (this.actionOption != "email" && this.actionOption != "phone") {
        return;
      }

      this.actionOption == "phone"
        ? this.onSendToPhoneNumber()
        : this.sendToEmail();
    },

    handleClose(done) {
      this.actionOption = "";
      this.$emit("on-close");
      done();
    },

    async sendToEmail() {
      try {
        this.isLoading = true;

        let request = await this.$http.post("staff/send-auth-email-code", {
          temporaryToken: this.temporaryToken,
        });

        if (request.data.success && request.data.message == "EMAIL_CODE_SENT") {
          this.$emit("on-close", { authMethod: "EMAIL" });
        } else if (request.data.message == "INVALID_INFO_SUPPLIED") {
          this.showFailedMessage(
            "Session Timed Out",
            "Your Login Attempt Session Expired, Please Log in again to continue"
          );
          return this.$emit("on-session-expired");
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while Saving Phone Auth Verification ID",
            request.data,
            request
          );

          return this.showFailedMessage(
            "Unable to Complete Authentication",
            "Unable to Complete Phone Number Authentication Now, Please try again"
          );
        }
      } catch (error) {
        this.showFailedMessage(
          "Authentication Failed",
          "Unable to Authenticate Log in now, Please Try again"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async onSendToPhoneNumber() {
      try {
        this.isLoading = true;

        this.confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(this.phoneNumber, window.recaptchaVerifier);

        try {
          let request = await this.$http.post(
            "staff/save-phone-auth-verification-id",
            {
              verification_id: this.confirmationResult.verificationId,
              temporaryToken: this.temporaryToken,
            }
          );

          if (
            request.data.success &&
            request.data.message == "VERIFICATION_ID_SAVED"
          ) {
            this.$emit("on-close", {
              confirmationResult: this.confirmationResult,
              authMethod: "PHONE",
            });
          } else if (request.data.message == "INVALID_INFO_SUPPLIED") {
            this.showFailedMessage(
              "Session Timed Out",
              "Your Login Attempt Session Expired, Please Log in again to continue"
            );
            return this.$emit("on-session-expired");
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected API response while Saving Phone Auth Verification ID",
              request.data,
              request
            );

            return this.showFailedMessage(
              "Unable to Complete Authentication",
              "Unable to Complete Phone Number Authentication Now, Please try again"
            );
          }
        } catch (error) {
          this.showFailedMessage(
            "Authentication Failed",
            "Unable to Authenticate Log in now, Please Try again"
          );
        }
      } catch (error) {
        if (error.code == "auth/invalid-phone-number") {
          return this.showFailedMessage(
            "Invalid Phone Number",
            "Your Phone Number is Invalid, Please talk to admin to correct it but you can use email Verification in the mean time."
          );
        } else if (error.code == "auth/too-many-requests") {
          return this.showFailedMessage(
            "Too Many Attempts",
            "You have attempted to use Phone Number Authentication too many times. Please use email instead or try again later."
          );
        } else if (error.code == "auth/quota-exceeded") {
          this.$rollbar.critical(
            "ADMIN FRONT END: Firebase Phone Auth Quota Usage Exceeded!!",
            error
          );
          return this.showFailedMessage(
            "Unable to Authenticate",
            "Unable to verify Phone Code right now. Please use email verification at the moment"
          );
        }
        this.$rollbar.critical(
          "ADMIN FRONT END: Firebase auth().signInWithPhoneNumber in ChooseAuthMethodDialog has an error",
          error
        );

        this.showFailedMessage(
          "Unable to Complete Authentication",
          "Unable to Complete Phone Number Authentication Now, Please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },

  watch: {
    visible() {
      this.isVisible = this.visible;
    },
  },

  computed: {
    // isVisible(){
    //   return this.visible;
    // },
    formattedEmail() {
      if (!this.email) return "";
      const emailParts = this.email.split("@");
      const visiblePart = emailParts[0].substr(0, 3);
      const invisblePart = emailParts[0].substr(3).replaceAll(/\w/g, "*");
      return `${visiblePart}${invisblePart}@${emailParts[1]}`;
    },
    formattedPhone() {
      if (!this.phoneNumber) return "";

      const visiblePart = this.phoneNumber.substr(0, 6);
      const invisblePart = this.phoneNumber
        .substr(3, this.phoneNumber.length - 7)
        .replaceAll(/\w/g, "*");
      return `${visiblePart}${invisblePart}${this.phoneNumber.substr(
        this.phoneNumber.length - 1
      )}`;
    },
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    temporaryToken: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },
};
</script>


<style>
.didnt-receive-code-dialog .el-dialog__title {
  font-weight: 500;
}

.didnt_receive_code .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .didnt_receive_code .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .didnt_receive_code .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .didnt_receive_code .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .didnt_receive_code .el-dialog {
    width: 90%;
  }
}
</style>