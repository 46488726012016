<template>
  <div class="container-fluid px-0 h-100">
    <div v-blur="blurConfig" class="row w-100 mx-0 h-100">
      <div class="col-12 col-md-5">
        <div class="row w-100 h-100 mx-0 align-items-center">
          <div class="col-12 px-0">
            <div class="row w-100">
              <div
                class="col-12 px-0"
                style="height: 30px; margin-bottom: 60px"
              >
                <img
                  :src="$store.state.logoImageUrl"
                  style="width: 200px"
                  alt="logo"
                  lass="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-12 pt-3"
              style="font-weight: 500; color: var(--el-app-primary)"
            >
              Admin E-learning Portal
            </div>

            <div
              class="col-12 mt-3"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
            >
              Enter your details to login.
            </div>
            <div class="col-12 mt-2">
              <input
                type="email"
                placeholder="Your email"
                v-model="$v.emailOrPhone.$model"
                @keyup.enter="loginUser"
                class="legacy-input bg-white"
                style="width: 265px; height: calc(2.8rem) !important"
              />
            </div>
            <div class="col-12" style="margin-top: 0.6rem !important">
              <input
                type="password"
                v-model="$v.password.$model"
                @keyup.enter="loginUser"
                placeholder="Your password"
                class="legacy-input bg-white"
                style="width: 265px; height: calc(2.8rem) !important"
              />
            </div>
            <div class="col-12 mt-2" style="position: relative; left: -70px">
              <div class="form-group">
                <input type="checkbox" class="filled-in" id="addPupil" />
                <label
                  for="addPupil"
                  style="
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 13px;
                  "
                  ><span style="position: relative; bottom: 3px; left: -5px">
                    Remember me</span
                  >
                </label>
              </div>
            </div>

            <div class="col-12 mt-1">
              <button
                v-on:click="loginUser"
                v-if="!isBusy"
                id="userLogin"
                :disabled="$v.emailOrPhone.$invalid || $v.password.$invalid"
                type="button"
                class="btn z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  width: 265px;
                  text-transform: capitalize;
                  height: 45px;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 1px">Sign In</span>
              </button>
              <ScaleOut v-else :background="'#164B70'" />
            </div>
          </div>
          <AnonymousUserContactUsDialog />
        </div>
      </div>
      <div class="col-7 d-md-block d-none px-0 h-100">
        <div
          class="row w-100 h-100 mx-0 align-items-center"
          style="background-color: #edeef2; border-radius: 0px 0px 0px 0px"
        >
          <div class="col-12 px-0">
            <img
              :src="$store.state.backgroundImage"
              alt=""
              style="height: 100vh; object-fit: cover"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <ChooseAuthMethodDialog
      :visible="chooseAuthMethodDialogVisible"
      :email="chooseAuthMethodDialogEmail"
      :phoneNumber="chooseAuthMethodDialogPhoneNumber"
      :temporaryToken="temporaryToken"
      @on-close="onDialogClose"
      @on-session-expired="onSessionExpired"
    />
  </div>
</template>

<script>
import { ScaleOut } from "vue-loading-spinner";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import AnonymousUserContactUsDialog from "../components/dialogs/anonymous-user-contact-us-dialog";
import ChooseAuthMethodDialog from "../components/dialogs/choose-auth-method-dialog";

import jwt_decode from "jwt-decode";

// import firebase from "firebase/app";
import "firebase/auth";

export default {
  mixins: [validationMixin],
  components: {
    ScaleOut,
    AnonymousUserContactUsDialog,
    ChooseAuthMethodDialog,
  },
  data() {
    return {
      emailOrPhone: process.env.VUE_APP_DEVELOPMENT_EMAIL || "",
      password: process.env.VUE_APP_DEVELOPMENT_PASSWORD || "",
      isBusy: false,
      email: "",
      temporaryToken: "",
      chooseAuthMethodDialogVisible: false,
      chooseAuthMethodDialogEmail: "",
      chooseAuthMethodDialogPhoneNumber: "",
      blurConfig: {
        isBlurred: false,
        opacity: 0.9,
        filter: "blur(1.8px)",
        transition: "all .3s linear",
      },
    };
  },

  computed: {},

  validations: {
    emailOrPhone: { required, email },
    password: { required, minLength: minLength(6) },
  },

  // mounted() {
  //   const self = this;

  //   // Start Firebase invisible reCAPTCHA verifier
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "userLogin",
  //     {
  //       size: "invisible",
  //       callback: () => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         self.sendSMS();
  //       },
  //     }
  //   );

  //   window.recaptchaVerifier.render().then((widgetId) => {
  //     window.recaptchaWidgetId = widgetId;
  //   });
  // },

  methods: {
    onSessionExpired() {
      this.emailOrPhone = "";
      this.password = "";
      this.chooseAuthMethodDialogVisible = false;
      this.blurConfig.isBlurred = false;
    },

    onDialogClose(payload) {
      this.chooseAuthMethodDialogVisible = false;
      this.blurConfig.isBlurred = false;

      if (payload) {
        //Redirect to enter Code Screen
        this.$router.push({
          name: "EnterVerificationCode",
          params: {
            confirmationResult: payload.confirmationResult,
            authMethod: payload.authMethod,
            temporaryToken: this.temporaryToken,
          },
        });
      }
    },

    async loginUser() {
      if (this.$v.emailOrPhone.$invalid || this.$v.password.$invalid) return;
      try {
        this.isBusy = true;
        let loginData = {
          email_phone_number: this.emailOrPhone,
          password: this.password,
        };
        let request = await this.$http.post("staff/login", loginData);
        if (
          request.data.success &&
          request.data.message == "CHOOSE_AUTHORIZATION_METHOD"
        ) {
          var decoded = jwt_decode(request.data.temporaryToken);
          this.blurConfig.isBlurred = true;

          this.temporaryToken = request.data.temporaryToken;
          this.chooseAuthMethodDialogEmail = decoded.email;
          this.chooseAuthMethodDialogPhoneNumber = decoded.phoneNumber;

          this.chooseAuthMethodDialogVisible = true;
        } else if (
          request.data.success &&
          request.data.message == "LOGIN_SUCCESSFUL"
        ) {
          this.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${request.data.accessToken}`;
          this.$store.commit("LOGIN_USER", request.data);

          this.$router.replace({ path: "/dashboard/home" });
          this.showSuccessMessage(
            "Login Successful",
            "You were successfully logged in"
          );
        } else if (request.data.message == "STAFF_NOT_FOUND") {
          this.showFailedMessage(
            "Account Not Found",
            "An account with the matching credentials wasnt found."
          );
        } else if (request.data.message == "INCORRECT_CREDENTIALS") {
          this.showFailedMessage(
            "Incorrect Credentials",
            "The Credentials you supplied were invalid. Please review them and try again."
          );
        } else if (request.data.message == "NO_ADMIN_PRIVELEDGES") {
          this.showFailedMessage(
            "No Admin Priviledges",
            "You do not have Admin Priviledges, please contact your Administrator."
          );
        } else if (request.data.message == "ACCOUNT_DEACTIVATED") {
          this.showFailedMessage(
            "Account Deactivated",
            "Unable to log in now because your account was deactivated."
          );
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.showFailedMessage(
          "Login Failed",
          "Unable to Complete login now. Please try again."
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style scoped>
body,
div#app,
html {
  background-color: #fff !important;
  height: 100% !important;
}

[type="checkbox"][class*="filled-in"]:checked + label:after {
  border-color: var(--el-app-primary);
  background-color: var(--el-app-primary);
  z-index: 0;
}
</style>
